import '../../scss/security/inscription_auth.scss';
import { render } from 'react-dom';
import * as React from 'react';
import { AuthContainer } from "./components/authContainer";
document.addEventListener('DOMContentLoaded', function () {
    initReactRoot();
});
var initReactRoot = function () {
    var root = document.querySelector('#root');
    if (!root) {
        return;
    }
    render(React.createElement(AuthContainer, null), root);
};
