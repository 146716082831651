import * as React from "react";
import { DescriptionBlock } from "@js/common/auth/components/descriptionBlock";
import { FormBlock } from "@js/common/auth/components/formBlock";
import { FormBlockMode } from "@js/common/auth/enums";
import { useMemo } from "react";
import { SuccessBlock } from "@js/common/auth/components/successBlock";
import { generateRoute, translate } from "@js/common/helpers";
import { useAuth } from "@js/common/auth/hooks/useAuth";
import { AuthContext } from '../../common/auth/contexts';
var AuthContainer = function () {
    var auth = useAuth();
    var linkToHomepage = (React.createElement("a", { className: "btn", href: generateRoute('homepage') }, translate('auth.form.type.create_account.action.go_back_to_tagwalk')));
    var successBlock = useMemo(function () {
        return React.createElement("div", { className: "success-block" },
            React.createElement(SuccessBlock, { action: linkToHomepage }));
    }, []);
    var formBlock = useMemo(function () {
        return (React.createElement(FormBlock, { mode: FormBlockMode.Link }));
    }, [auth.isConnect]);
    return (React.createElement("div", { className: "auth-page-container auth-body" },
        React.createElement(AuthContext.Provider, { value: auth },
            React.createElement(DescriptionBlock, null),
            React.createElement("div", { className: "right-block" }, auth.registrationStep < auth.maxStepCount ? formBlock : successBlock))));
};
export { AuthContainer };
